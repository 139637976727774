function getTopOffset (element) {
  return element.getBoundingClientRect().top + window.scrollY - document.documentElement.clientTop;
}

function scrollTop () {
  return (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
}

function toggleClassName (element, className) {
  if (element.classList.contains(className))
    element.classList.remove(className);
  else
    element.classList.add(className);
}

class MobileNav {
  constructor (navContainer, toggleButton, headerContainer) {
    this.navContainer = navContainer;
    this.toggleButton = toggleButton;
    this.headerContainer = headerContainer;
  }

  hook () {
    this.toggleButton.addEventListener('click', event => {
      event.preventDefault();
      event.stopPropagation();
      this.toggleMenu();
    });

    // Clicking in the document will close the menu ...
    document.addEventListener('click', () => this.closeMenu());
    // Clicking in the nav container stops the event's propagation effectively
    // overriding the listener on the document
    this.navContainer.addEventListener('click', event => event.stopPropagation());

    this.scroll();
  }

  toggleMenu () {
    toggleClassName(this.navContainer, 'mobile-nav-visible');
    toggleClassName(document.body, 'clickable');
  }

  closeMenu () {
    this.navContainer.classList.remove('mobile-nav-visible');
    document.body.classList.remove('clickable');
  }

  scroll () {
    const stickyNavTop = getTopOffset(this.headerContainer);
    const stickyNav = () => {
      if (scrollTop() > stickyNavTop)
        this.headerContainer.classList.add('sticky');
      else
        this.headerContainer.classList.remove('sticky');
    };
    window.addEventListener('scroll', stickyNav);
    stickyNav();
  }

  static ready () {
    const mobileNavContainer = document.querySelector('.js-mobile-nav');
    if (mobileNavContainer === null)
      return;

    const comp = new MobileNav(mobileNavContainer, document.querySelector('.js-mobile-nav-btn'), document.querySelector('.js-mobile-header'));
    comp.hook();
    return comp;
  }
}

export default MobileNav;
