<template>
  <div>
    <div v-if="abbreviation" :style="`margin-left: ${shadowValueWidth}px; margin-top: 1px;`" class="absolute silver f6"> ({{ abbreviation }})</div>
    <input v-model="value" type="number" :name="name" :required="required" :min="min" :max="max" :autocomplete="autocomplete" :placeholder="placeholder" :step="step" class="pa0 outline-0 bn w-100">
    <span ref="shadowValue" class="dib" style="overflow-y: hidden; height: 1px;">{{ value }}&nbsp;</span>
  </div>
</template>

<script>
export default {
  props: {
    initialValue: {
      type: [Number, String],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    step: {
      type: [Number, String],
      default: null
    },
    min: {
      type: [Number, String],
      default: null
    },
    max: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      value: this.initialValue,
      shadowValueWidth: 0
    };
  },
  mounted () {
    this.setShadowValueWidth();
  },
  watch: {
    value () {
      this.$nextTick(() => this.setShadowValueWidth());
    }
  },
  computed: {
    abbreviation () {
      const units = {
        q: 1000000000000000,
        t: 1000000000000,
        b: 1000000000,
        m: 1000000
      };

      for (const unit in units)
        if (this.value >= units[unit]) {
          const estimate = Math.round(this.value / units[unit]);
          const exact = this.value / units[unit] === estimate;
          return `${exact ? '' : '~'}${estimate}${unit}`;
        }

      if (this.value !== null && this.value.toString().length)
        return '< 1m';

      return null;
    }
  },
  methods: {
    setShadowValueWidth () {
      this.shadowValueWidth = this.$refs.shadowValue.clientWidth;
    }
  }
};
</script>
