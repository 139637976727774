<template>
  <div>
    <div v-if="selectedEvent" class="mv3">
      <span class="b">Filter:</span>
      <label class="ph2 pv1 mh2 mv1 ba br3 bg-near-white b--silver dim pointer">
        <input type="checkbox" :value="selectedEvent" :name="selectedEvent" @change="clearFilter()" class="dn"/>
        <span class="text-normal">{{ humanize(selectedEvent) }}</span>
        <span class="pl2 icon-remove"></span>
      </label>
    </div>
    <details class="mt3 br3 bg-light-gray">
      <summary class="pa3 pointer">Events</summary>
      <div class="pa3 bg-white ba b--light-gray">
        <div v-for="(category, categoryKey) in items" :key="categoryKey">
          <h3>{{ capitalize(humanize(categoryKey)) }}</h3>
          <div class="flex flex-wrap pb4">
            <label class="ph2 pv1 mh2 mv1 ba br3 bg-near-white b--silver dim pointer">
              <input type="checkbox" value="all" :name="categoryKey" @change="updateUrl(categoryKey)" class="dn"/>
              <span class="text-normal">All</span>
            </label>
            <template v-for="event in category">
              <label class="ph2 pv1 mh2 mv1 ba br3 bg-near-white b--silver dim pointer">
                <input type="checkbox" :value="event" :name="categoryKey" @change="updateUrl(`${categoryKey}+${event}`)" class="dn"/>
                <span class="text-normal">{{ humanize(event) }}</span>
              </label>
            </template>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
export default {
  props: ['tags'],
  mounted () {
    this.items = JSON.parse(this.tags);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('type') && urlParams.has('event'))
      this.selectedEvent = urlParams.get('event');
    else if (urlParams.has('type'))
      this.selectedEvent = `All ${urlParams.get('type')}s`;
  },
  data () {
    return {
      items: [],
      selectedEvent: null
    };
  },
  methods: {
    updateUrl (selection) {
      const [type, event] = selection.split('+');
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('type', type);
      if (event)
        urlParams.set('event', event);
      window.history.replaceState(null, null, `?${urlParams.toString()}`);
      window.location.reload();
    },
    humanize (str) {
      const words = str.split('_');
      const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      const humanizedString = capitalizedWords.join(' ');
      return humanizedString;
    },
    capitalize (str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    clearFilter () {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.delete('type');
      urlParams.delete('event');

      window.history.replaceState(null, null, `?${urlParams.toString()}`);
      window.location.reload();
    }
  }
};
</script>
