<template>
  <div class="flex">
    <div class="w-100 w-30-ns mr2">
      <label for="all" class="mr2 text-normal">
        <input type="checkbox" v-model="allRegions" id="all" v-bind:name="regionModelName" value="global" />
        All regions
      </label>
    </div>
    <div class="flex flex-wrap">
      <div v-for="region in regions" v-bind:key="region.code" class="w-100 w-30-ns dib pr4">
        <label v-bind:for="region.code" class="text-normal">
          <input type="checkbox" v-model="region.selected" v-bind:id="region.code" v-bind:name="regionModelName" v-bind:value="region.code" />
          {{ region.name }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['recordRegions', 'regionModelName'],
  mounted () {
    this.regions = JSON.parse(this.recordRegions);
    if (this.regions.every((region) => region.selected === false))
      this.allRegions = true;
  },
  data () {
    return {
      regions: [],
      allRegions: false
    };
  },
  watch: {
    regions: {
      handler (regions) {
        if (regions.every((region) => region.selected === true)) {
          this.selectAllRegions();
          this.allRegions = true;
        }
        if (regions.some((region) => region.selected === true))
          this.allRegions = false;
      },
      deep: true
    },
    allRegions (newValue) {
      if (newValue)
        this.selectAllRegions();
    }
  },
  methods: {
    selectAllRegions () {
      this.regions.forEach((region) => region.selected = false);
    }
  }
};
</script>
