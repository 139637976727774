const clickAway = (el, func) => {
  const listener = (event) => {
    if (el.contains(event.target))
      return;

    func();
  };

  document.addEventListener('click', listener, false);
  document.addEventListener('touchstart', listener, false);
};

export default clickAway;
