<template>
  <div class="secondary-dns-primary-nameservers">
    <div class="records">
      <div v-if="!loading">
        <div v-if="loadError" class="secondary-dns-primary-load-error">Error in loading name server information. Please try refreshing the page.</div>
        <div v-if="!loadError && nameServers && nameServers.length > 0">
          <div v-for="nameServer in nameServers" :key="nameServer.hostname">
            <span v-if="nameServer.synced" class="icon-check green" role="tooltip" v-bind:title="nameServer.info"></span>
            <span v-else class="icon-warning" role="tooltip" v-bind:title="nameServer.info"></span>
            {{ nameServer.hostname }}
          </div>
        </div>
      </div>
      <slot v-else></slot>
    </div>
  </div>
</template>

<script>
import EventBus from '../../javascript/common/event-bus.js';

export default {
  props: [],
  mounted () {
    EventBus.$on('primary-diagnosis', this.load);
  },
  data () {
    return {
      nameServers: null,
      loading: true,
      loadError: false
    };
  },
  methods: {
    load (eventData) {
      if (eventData.hasFailed || eventData.hasErrored) {
        this.loadError = true;
        this.loading = false;
      } else if (eventData.response) {
        this.nameServers = eventData.response.name_servers;
        this.loadError = false;
        this.loading = false;
      }
    }
  }
};
</script>
