<template>
  <div>
    <slot />
  </div>
</template>

<script>
const VISIBLE_SELECTOR = 'visible-for';
const isCheckbox = (el) => el && el.getAttribute('type') === 'checkbox';
const showByValue = (el, value) => {
  const elValue = el.getAttribute(VISIBLE_SELECTOR) || '';

  if (!value.length)
    return !elValue.length;

  return elValue.indexOf(value) !== -1;
};
const getValue = (selectorEl) => {
  if (!selectorEl)
    return '';

  if (isCheckbox(selectorEl))
    return selectorEl.checked ? selectorEl.value : '';

  return selectorEl.value || '';
};

export default {
  props: {
    selector: {
      type: String
    },
    wrapper: {
      type: Object,
      default () { return document; }
    },
    outOfDomBuffer: {
      type: HTMLElement,
      default () { return document.createElement('div'); }
    }
  },
  mounted () {
    const $els = [...this.wrapper.querySelectorAll(this.selector)];

    $els.forEach((el) => {
      el.onchange = (event) => this.select(event.target);
    });

    const initialSelection = $els[0] && $els[0].tagName === 'SELECT' ? $els[0] : $els.find(($el) => $el.checked);

    this.select(initialSelection);
  },
  methods: {
    select (selectorEl) {
      const selectedValue = getValue(selectorEl);
      const removedEls = [...this.outOfDomBuffer.querySelectorAll(`:scope > [${VISIBLE_SELECTOR}]`)];
      const inDomEls = [...this.$el.querySelectorAll(`:scope > [${VISIBLE_SELECTOR}]`)];

      removedEls.concat(inDomEls).forEach((el) => {
        if (showByValue(el, selectedValue))
          this.$el.appendChild(el);
        else
          this.outOfDomBuffer.appendChild(el);
      });
    }
  }
};
</script>
