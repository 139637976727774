import 'core-js/stable';
import 'regenerator-runtime/runtime';

import $ from '../common/jquery.js';
import ajax from '../common/ajax.js';
import initBugsnag from '../common/bugsnag.js';
import Rails from '@rails/ujs';

window.$ = $;
window.ajax = ajax;
window.Rails = Rails;

initBugsnag();

Rails.start();
