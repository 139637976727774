<template>
  <table>
    <thead v-if="hasHeader">
      <tr>
        <th v-for="attr in attrs" v-bind:key="'header-' + attr.key" v-html="attr.title" />
        <th></th>
      </tr>
    </thead>
     <tbody>
      <tr v-for="item in items" v-bind:key="item.id" :class="isRemoved(item) ? 'is-hidden' : ''">
        <td v-for="attr in attrs" v-bind:key="item.id + '-' + attr.key" :class="attr.tdClass">
          <input-millions v-if="attr.type === 'millions'" :initial-value="item[attr.key]" class="form-control" :name="attr.name" :min="attr.min" :max="attr.max" :required="!isRemoved(item) && attr.required" :autocomplete="attr.autocomplete" :placeholder="attr.placeholder" />
          <textarea v-else-if="attr.type === 'textarea'" v-model="item[attr.key]" class="form-control" :name="attr.name" :rows="attr.rows" :required="!isRemoved(item) && attr.required" :autocomplete="attr.autocomplete" :placeholder="attr.placeholder"></textarea>
          <input v-else :type="inputType(attr)" v-model="item[attr.key]" class="form-control" :name="attr.name" :min="attr.min" :required="!isRemoved(item) && attr.required" :autocomplete="attr.autocomplete" :placeholder="attr.placeholder" :step="attr.step">
          <span class="error-block" v-html="errorFor(item, attr)"></span>
        </td>
        <td :class="tdActionsClass">
          <input v-if="isRemoved(item)" type="hidden" :name="destroyName" value="1">
          <input type="hidden" v-for="attr in hiddenAttrs" v-bind:key="item.id + '-' + attr.key" :name="attr.name" :value="item[attr.key]">
          <button type="button" v-if="undeletedItems.length > 1" @click.prevent="remove(item)" aria-label="Remove row" href="javascript:;" class="ml1 btn btn-default icon-align-center icon-trash"></button>
        </td>
      </tr>
    </tbody>
    <tfoot>
      <tr>
        <td :colspan="attrs.length + 1">
          <button type="button" aria-label="Add a row" class="btn btn-default" @click.prevent="add">
            + Add
          </button>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
import Tooltip from 'bootstrap/js/dist/tooltip';
import InputMillions from '../input-millions/component.vue';

const VALID_FIELD_TYPES = ['text', 'number', 'textarea'];
const DELETE_ATTRIBUTE = '_destroy';

const isEmpty = (item) => {
  const obj = Object.assign({}, item);

  delete obj.errors;
  delete obj[DELETE_ATTRIBUTE];

  return Object.values(obj).join('').trim().length === 0;
};

const FILTER_ITEMS_WITH_SIMILAR_ATTRIBUTE_VALUES = (item, attrs) => {
  return (i) => {
    for (let n = 0; n < attrs.length; n++)
      if (i[attrs[n]] !== item[attrs[n]])
        return false;

    return true;
  };
};

export default {
  props: ['attrs', 'hiddenAttrs', 'tdActionsClass', 'state', 'destroyName', 'duplicateAttributes'],
  components: {
    'input-millions': InputMillions
  },
  computed: {
    undeletedItems () {
      return this.items.filter((item) => !item[DELETE_ATTRIBUTE]);
    },
    hasHeader () {
      return !!this.attrs.filter((attr) => attr.title).length;
    }
  },
  mounted () {
    this.$el.addExpandableTableItem = (item) => {
      const firstItem = this.items[0];

      if (this.items.length === 1 && isEmpty(firstItem))
        for (const key in item)
          firstItem[key] = item[key];
      else
        this.add(item);
    };

    this.$el.clearExpandableTable = () => {
      this.removeAllItems();
    };

    const $tooltipEls = [...this.$el.querySelectorAll('[role="tooltip"][title]')];

    $tooltipEls.forEach(($el) => {
      new Tooltip($el, { title: $el.getAttribute('title') });
    });
  },
  data () {
    return {
      items: (this.state || []).map((item) => this.buildItem(item))
    };
  },
  methods: {
    add (item) {
      item = item || {};

      if (this.isDuplicate(item))
        return;

      this.items.push(this.buildItem(item));
      this.focusLastRow();
    },
    focusLastRow () {
      setTimeout(() => {
        this.$el.querySelector('tbody tr:last-child').querySelector('input, textarea').focus();
      }, 0);
    },
    remove (item) {
      if (this.destroyName)
        item[DELETE_ATTRIBUTE] = true;
      else {
        const index = this.items.indexOf(item);
        this.items.splice(index, 1);
      }
    },
    removeAllItems () {
      this.items.splice(0, this.items.length);
    },
    isRemoved (item) {
      if (this.destroyName)
        return !!item[DELETE_ATTRIBUTE];
      else
        return this.items.indexOf(item) === -1;
    },
    isDuplicate (item) {
      const duplicateAttributes = this.duplicateAttributes || [];

      if (!duplicateAttributes.length)
        return false;

      return this.items
        .filter(FILTER_ITEMS_WITH_SIMILAR_ATTRIBUTE_VALUES(item, duplicateAttributes))
        .length > 0;
    },
    buildItem (item) {
      if (typeof item !== 'object')
        item = { value: item };

      const obj = Object.assign({}, item);

      obj[DELETE_ATTRIBUTE] = false;
      obj.errors = item.errors || [];

      this.attrs.forEach((attr) => {
        obj[attr.key] = this.getValue(item, attr);
      });

      return obj;
    },
    getValue (item, attr) {
      if (typeof item[attr.key] !== 'undefined')
        return item[attr.key];
      else if (typeof attr.default !== 'undefined')
        return attr.default;
      else
        return undefined;
    },
    inputType (attr) {
      if (VALID_FIELD_TYPES.indexOf(attr.type))
        return attr.type;

      return 'text';
    },
    errorFor (item, attr) {
      const errors = item.errors[attr.key];

      if (!errors || !errors.length)
        return;

      return errors[0];
    }
  }
};
</script>
