<template>
  <div>
    <Datepicker v-model="date" @update:model-value="dateSelected" range auto-apply v-bind:partial-range="false" v-bind:enable-time-picker="false" v-bind:clearable="false"/>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

const date = ref();

onMounted(() => {
  const params = new URLSearchParams(window.location.search);

  const from = params.get('from') ? new Date(params.get('from')) : new Date(new Date().setDate(new Date().getDate() - 30));
  const to = params.get('to') ? new Date(params.get('to')) : new Date();

  date.value = [from, to];
});

const dateSelected = (modelData) => {
  const from = modelData[0].toISOString().substr(0, 10);
  const to = modelData[1].toISOString().substr(0, 10);

  const newUrl = `?from=${from}&to=${to}`;
  history.pushState({}, null, newUrl);
  location.reload();
};

</script>
