<template>
  <div>
    <template v-if="state == 'ready'">
      <button type="button" class="pa0 bn bg-transparent gray" v-on:click="copy">Copy to clipboard</button>
    </template>
    <template v-if="state == 'copied'">
      <span class="icon-check gray">Copied</span>
    </template>
    <template v-if="state == 'error'">
      <span class="gray">Error :(</span>
    </template>
  </div>
</template>

<script>
export default {
  props: ['content'],
  mounted () {
  },
  data () {
    return {
      state: 'ready',
      clipboard: navigator.clipboard
    };
  },
  methods: {
    async copy () {
      try {
        await this.clipboard.writeText(this.content);
        this.state = 'copied';
      } catch (err) {
        this.state = 'error';
      } finally {
        setTimeout(() => {
          this.state = 'ready';
        }, 1500);
      }
    }
  }
};
</script>
