<template>
    <div>
        <div v-if="loading && !hideLoading">
            <slot name="loading" v-bind:response="response" v-bind:is-initial-load="isInitialLoad">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="42" height="42" fill="gray">
                    <circle transform="translate(8 0)" cx="0" cy="16" r="0">
                        <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0"
                                 keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
                                 calcMode="spline"/>
                    </circle>
                    <circle transform="translate(16 0)" cx="0" cy="16" r="0">
                        <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.3"
                                 keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
                                 calcMode="spline"/>
                    </circle>
                    <circle transform="translate(24 0)" cx="0" cy="16" r="0">
                        <animate attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.6"
                                 keytimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8"
                                 calcMode="spline"/>
                    </circle>
                </svg>
            </slot>
        </div>
        <div v-if="hasSucceeded" class="success">
            <slot name="success" v-bind:response="response" v-bind:refresh="refresh" v-bind:is-initial-load="isInitialLoad"></slot>
        </div>
        <div v-if="hasFailed">
            <slot name="failure"></slot>
        </div>
        <div v-if="hasErrored">
            <slot name="error"></slot>
        </div>
    </div>
</template>

<script>
import ajax from '../../javascript/common/ajax.js';
import EventBus from '../../javascript/common/event-bus.js';
import Tooltip from 'bootstrap/js/dist/tooltip';

export default {
  props: ['loadUrl', 'hideLoading', 'eventName'],
  mounted () {
    if (this.loadUrl !== null && this.loadUrl !== undefined)
      this.load();
  },
  data () {
    return {
      response: '',
      hasSucceeded: false,
      hasFailed: false,
      hasErrored: false,
      loading: false,
      isInitialLoad: true
    };
  },
  methods: {
    load () {
      this.loading = true;
      ajax(this.loadUrl).then(
        response => {
          this.hasSucceeded = true;
          this.response = response.getAt('response', '');
        },
        error => {
          const clientError = error.isClientError();
          if (clientError)
            this.hasFailed = true;
          else
            this.hasErrored = true;
        }
      ).finally(() => {
        this.isInitialLoad = false;
        this.loading = false;
        this.loadNewTooltipsInDOM();
        if (this.eventName !== null && this.eventName !== undefined)
          EventBus.$emit(this.eventName, { response: this.response, hasSucceeded: this.hasSucceeded, hasFailed: this.hasFailed, hasErrored: this.hasErrored });
      });
    },
    loadNewTooltipsInDOM () {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[role*="tooltip"]'));
      tooltipTriggerList.map(function (el) {
        return new Tooltip(el, {
          popperConfig: function (config) {
            return {};
          }
        });
      });
    },
    refresh () {
      this.response = '';
      this.hasSucceeded = false;
      this.hasFailed = false;
      this.hasErrored = false;
      this.loading = false;

      this.load();
    }
  }
};
</script>
