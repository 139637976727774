<template>
  <section>
    <div v-on:click.prevent="showModal">
      <slot name="button"></slot>
    </div>
    <div v-show="show" :class="`modal-wrapper ${isNarrow ? 'narrow' : ''}`">
      <div class="modal-bg" v-on:click="closeModal"></div>
      <div class="modal-window animation-appear-top text-left">
        <div class="flex justify-between pb3">
          <h2 class="ma0"><slot name="header"></slot></h2>
          <a href="javascript:;" v-on:click="closeModal" class="f3 icon-remove moon-gray hover-silver-gray"></a>
        </div>
        <slot name="body" v-bind:closeModal="closeModal"></slot>
        <input v-if="confirmation" v-model="input" v-on:keyup="keyup" type="text" class="form-control">
        <div class="pt4 pull-right" v-if="hasClose">
          <button v-on:click="closeModal" type="button" class="btn btn-default">{{closeText || 'Cancel'}}</button>
          <a v-if="submitText" ref="confirmBtn" :disabled="disabled" v-on:click="submit" :data-method="method" :href="submitHref" :class="submitClass" class="btn btn-primary ml2" :id="submitId">
            <template v-if="loading">{{ loadingText }}</template>
            <template v-else>{{ submitText }}</template>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import EventBus from '../../javascript/common/event-bus.js';
import Tooltip from 'bootstrap/js/dist/tooltip';
import { KeyCodes } from './keycodes';
import { FILTER_VISIBLE_ELEMENTS } from '../../javascript/common/filters.js';

export default {
  props: {
    confirmation: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    },
    method: {
      type: String,
      default: ''
    },
    submitText: {
      type: String,
      default: ''
    },
    loadingText: {
      type: String,
      default: 'Loading...'
    },
    submitId: {
      type: String,
      default: ''
    },
    closeText: {
      type: String,
      default: ''
    },
    hasClose: {
      type: Boolean,
      default: true
    },
    closeConfirmation: {
      type: String,
      default: null
    },
    initialShow: {
      type: Boolean,
      default: false
    },
    modalCloseTriggerEventName: {
      type: String,
      default: null
    },
    isNarrow: {
      type: Boolean,
      default: false
    },
    onSubmit: {
      type: Function,
      default () { return () => {}; }
    },
    urlBuilder: {
      type: Function,
      default () { return window.URL; }
    },
    filterVisibleElements: {
      type: Function,
      default: FILTER_VISIBLE_ELEMENTS
    }
  },
  mounted () {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === KeyCodes.ESC)
        this.closeModal();
    });

    if (this.modalCloseTriggerEventName)
      EventBus.$on(this.modalCloseTriggerEventName, this.closeModal);

    const $tooltipEls = [...this.$el.querySelectorAll('[role="tooltip"][title]')];
    $tooltipEls.forEach(($el) => {
      new Tooltip($el, { title: $el.getAttribute('title') });
    });

    const $formEls = [...this.$el.querySelectorAll('[name]')];
    $formEls.forEach(($el) => {
      $el.addEventListener('change', () => this.updateFormUrl());
      $el.addEventListener('keyup', () => this.updateFormUrl());
    });

    this.updateFormUrl();
  },
  data () {
    return {
      show: this.initialShow,
      input: '',
      loading: false,
      params: {}
    };
  },
  computed: {
    disabled () {
      if (this.loading)
        return true;

      if (!this.confirmation.length)
        return undefined;

      if (this.input.toLowerCase() === this.confirmation.toLowerCase())
        return undefined;

      return true;
    },
    submitClass () {
      return this.disabled ? 'disabled' : '';
    },
    submitHref () {
      const Builder = this.urlBuilder();
      const url = new Builder(this.path, window.location.origin);

      for (const name in this.params)
        url.searchParams.set(name, this.params[name]);

      return url.toString();
    }
  },
  methods: {
    showModal (event) {
      event.stopPropagation();

      this.show = true;

      this.$nextTick(() => {
        const firstElement = [...this.$el.querySelectorAll('textarea, input')].find(this.filterVisibleElements);

        if (firstElement)
          firstElement.focus();
      });
    },
    closeModal (showConfirmation = true) {
      if (showConfirmation && this.closeConfirmation && !confirm(this.closeConfirmation)) return false;

      this.show = false;
      return true;
    },
    keyup (event) {
      if (event.keyCode === KeyCodes.ENTER)
        if (this.input === this.confirmation)
          this.$refs.confirmBtn.click();
    },
    submit (event) {
      if (!this.path) {
        event.preventDefault();
        this.closeModal(false);
        if (typeof this.onSubmit === 'function')
          this.onSubmit();
      } else if (this.loading)
        event.preventDefault();
      else
        this.loading = true;
    },

    updateFormUrl () {
      const $formEls = [...this.$el.querySelectorAll('[name]')];

      $formEls.forEach(($el) => {
        this.params[$el.getAttribute('name')] = $el.value;
      });
    }
  }
};
</script>
