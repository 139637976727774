import Tooltip from 'bootstrap/js/dist/tooltip';

class SystemStatus {
  constructor (el, ajax, tooltip) {
    this.el = el;
    this.ajax = ajax;
    this.Tooltip = tooltip;
  }

  loadStatusPageScript () {
    this.ajax({ url: 'https://statuspage-production.s3.amazonaws.com/se-v2.js', dataType: 'script', cache: true })
      .done(() => {
        if (window.StatusPage) {
          const api = new window.StatusPage.page({ page: 'tjym90yyv2zt' }); // eslint-disable-line new-cap
          this.displayStatus(api);
        }
      })
      .fail(() => {
        this.showTooltip('Cannot retrieve status');
      });
  };

  displayStatus (api) {
    api.summary({
      success: (data) => this.displayCurrentStatus(data),
      error: (data) => this.showTooltip('Cannot retrieve status')
    });
  }

  displayCurrentStatus (data) {
    this.el.className += ` ${this.getStatus(data)}`;
    this.showTooltip(data.status.description);
  }

  getStatus (data) {
    let icon = '';

    switch (data.status.indicator) {
      case 'none':
        icon = 'icon-green';
        break;
      case 'minor':
        icon = 'icon-yellow';
        break;
      case 'major':
      case 'critical':
        icon = 'icon-red';
        break;
      default:
        'icon-gray';
    };

    return icon;
  }

  showTooltip (title) {
    new this.Tooltip(this.el, { title });
  }
};

SystemStatus.ready = ($) => {
  const el = document.querySelector('.js-system-status');

  if (el) {
    const status = new SystemStatus(el, $.ajax, Tooltip);

    status.loadStatusPageScript();
  }
};

export default SystemStatus;
