<template>
  <div>
    <a href="javascript:;" v-on:click="toggle" class="db dark-gray text-centered nt1 nb1">
      <slot name="prompt">
        <span class="icon-ellipsis-vert ph2"></span>
      </slot>
    </a>
    <div v-show="show" class="l-relative">
      <nav :class="`dropdown-list f5 ${this.position}-0`">
        <slot name="nav"></slot>
      </nav>
    </div>
  </div>
</template>

<script>
import clickAway from '../../javascript/utils/clickaway.js';

export default {
  props: {
    position: {
      type: String,
      default: 'right'
    }
  },
  mounted () {
    clickAway(this.$el, () => this.show = false);
  },
  watch: {
    show (val) {
      if (!val)
        return;

      const asyncLinks = this.$parent.$refs;

      for (const key in asyncLinks)
        if (typeof asyncLinks[key].load === 'function')
          asyncLinks[key].load();
    }
  },
  data () {
    return {
      show: false
    };
  },
  methods: {
    toggle () {
      this.show = !this.show;
    }
  }
};
</script>
